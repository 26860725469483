var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "coupon_id",
          align: "center",
          "min-width": "100",
          label: "优惠券ID",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "coupon_name",
          align: "center",
          "min-width": "100",
          label: "优惠券名称",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "member_name",
          align: "center",
          "min-width": "100",
          label: "用户",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistic_name",
          align: "center",
          label: "城市仓",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "coupon_value",
          align: "center",
          label: "优惠券金额",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "time",
          align: "center",
          label: "发放时间",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "use_time",
          align: "center",
          label: "使用时间",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "state", align: "center", label: "使用状态" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.stateList.find(
                          (item) => item.value == scope.row.state
                        )?.label || "-"
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }