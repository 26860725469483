/** 状态枚举 */
export const STATE_ENUM = {
  10: {
    value: 10,
    label: "未使用",
  },
  20: {
    value: 20,
    label: "冻结",
  },
  30: {
    value: 30,
    label: "已使用",
  },
  40: {
    value: 40,
    label: "已过期",
  },
};

/** 城市仓审核状态 */
export const LOGISTICS_STATUS = {
  disabled: {
    value: "0",
    label: "禁用",
  },
  common: {
    value: "1",
    label: "启用",
  },
  all: {
    value: "all",
    label: "全部",
  },
};
