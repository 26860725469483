<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="d-flex headSearch">
        <div class="form-item">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="使用时间起"
            end-placeholder="使用时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="useTime"
            @change="changeDate"
          >
          </el-date-picker>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.coupon_id"
            placeholder="优惠券ID"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.logistic_id"
            filterable
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.name"
            placeholder="输入用户名称或者电话"
            clearable
          ></el-input>
        </div>
        <div>
          <el-date-picker
            v-model="timeValue"
            type="daterange"
            @change="timeChange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="发放起始日期"
            end-placeholder="发放终止日期"
          >
          </el-date-picker>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.state"
            filterable
            clearable
            placeholder="选择状态"
          >
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            :disabled="!tableData.length || disabledExport"
            @click="onHandleExport"
            icon="el-icon-download"
            >导出</el-button
          >
        </div>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list v-loading="loadingData" :tableData="tableData"></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
    <div class="d-flex a-center" style="justify-content: center">
      <el-button @click="onHandleReturn" type="primary">返回</el-button>
    </div>
  </section>
</template>
<script>
import { getCouponRecord } from "@/api/generalControl/coupon";
import { postCouponRecordsExport } from "@/api/export/center.js";
import { getAllLogisticsList } from "@/api/member/index";
import TableList from "./modules/table-list/index.vue";
import { STATE_ENUM, LOGISTICS_STATUS } from "./utils/enum";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ZoneConfig",
  components: { TableList },
  data() {
    return {
      useTime: "", // 使用时间
      timeValue: "",
      disabledExport: false,
      cityStoreList: [], // 城市仓列表
      total: 0,
      tableData: [],
      loadingData: false,
      formData: {
        logistic_id: "", // 城市仓id
        coupon_id: "", //优惠券id
        name: "",
        state: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      stateList: Object.values(STATE_ENUM), //状态列表
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    /**
     * 使用日期发生了变化
     */
    changeDate(val) {
      if (!val) {
        this.useTime = "";
      }
    },
    /**
     * 修改时间选择器
     */
    timeChange(val) {
      if (!val) {
        this.timeValue = "";
      }
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      const params = {
        status: LOGISTICS_STATUS.common.value,
      };
      try {
        const { data } = await getAllLogisticsList(params);
        this.cityStoreList = data;
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const params = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
          user_name: username,
          send_time: this.timeValue ? this.timeValue.join(",") : "",
          use_time: this.useTime ? this.useTime.join(",") : "",
        };
        delete params.page;
        delete params.pageSize;
        await postCouponRecordsExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postCouponRecordsExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxVersatileRegionList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxVersatileRegionList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxVersatileRegionList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxVersatileRegionList();
      this.ajaxGetCityStoreList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.timeValue = "";
      this.useTime = "";
      this.postAjaxVersatileRegionList();
    },
    /**
     * 获得列表数据
     */
    async postAjaxVersatileRegionList() {
      this.loadingData = true;
      console.log(this.timeValue, "time===");
      try {
        const { data } = await getCouponRecord({
          ...this.formData,
          send_time: this.timeValue ? this.timeValue.join(",") : "",
          use_time: this.useTime ? this.useTime.join(",") : "",
        });
        this.tableData = data.list;
        this.total = data.total;
      } catch (err) {
        console.error("ajax getCouponRecord err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 返回上一级 优惠券列表
     */
    onHandleReturn() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
    align-items: start;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
    flex-wrap: wrap;
  }
}
</style>
