<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="coupon_id"
      align="center"
      min-width="100"
      label="优惠券ID"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="coupon_name"
      align="center"
      min-width="100"
      label="优惠券名称"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="member_name"
      align="center"
      min-width="100"
      label="用户"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="logistic_name"
      align="center"
      label="城市仓"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="coupon_value"
      align="center"
      label="优惠券金额"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="time"
      align="center"
      label="发放时间"
      min-width="120"
    >
    </el-table-column>
    <el-table-column
      prop="use_time"
      align="center"
      label="使用时间"
      min-width="120"
    >
    </el-table-column>
    <el-table-column prop="state" align="center" label="使用状态">
      <template slot-scope="scope">
        <span>
          {{
            stateList.find((item) => item.value == scope.row.state)?.label ||
            "-"
          }}
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { STATE_ENUM } from "../../utils/enum";
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stateList: Object.values(STATE_ENUM),
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
